<template>
    <div>
        <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
			<div class="col-span-1 flex items-center flex-wrap">
				<h2 class="text-2xl font-bold mr-3">
					{{ tab | sentenceCase }} Leads
				</h2>
			</div>
			<div class="col-span-1">
				<div class="relative">
					<input
						type="search"
						name="search"
						class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
						placeholder="Search for merchant's name, status, etc."
						v-model="searchQuery"
					/>
					<ion-icon
						name="search-outline"
						class="text-blue-200 absolute z-10 text-lg ml-3 left-0"
						style="top: 50%; transform: translateY(-50%);"
					></ion-icon>
				</div>
			</div>
		</div>
    
        <div class="border border-solid border-blue-200">
          <div class="flex flex-col">
              <div class="duration-300">
                <datatable
                    :data="settlements.data"
                    :columns="columns"
                    :fillable="false"
                    :actions="actions"
                    :loading="settlements.loading"
                    ref="table"
                />
              </div>
          </div>
        </div>
        <div>
			<modal
			className="w-full md:w-2/5 xl:w-3/10 pt-10"
			ref="addCardModal"
			@close="removeError"
			>
			<!-- <h4 class="text-xl font-bold mb-6">Are you sure you want to mark?</h4> -->

			<!-- <template v-if="requestError">
				<div class="alert alert-red-soft mb-10">
				<div class="alert-icon">!</div>
				<span>{{ requestError }}</span>
				</div>
			</template> -->

			<div class="mb-6 font-bold">Are you sure you want to mark this lead?</div>

			<!-- <div class="flex flex-row items-center mb-6">
				<form-group
				v-model="transactionReference"
				placeholder="Enter Transaction Reference"
				class="custom-select border border-blue-500 rounded-sm text-xs text-blue-500 w-full"
				/>
			</div> -->

			<div class="flex flex-row items-center">
				<button
					type="button"
					class="btn btn-md btn-red-soft mr-2"
					@click.prevent="closeActivatePndModal"
				>
				Cancel
				</button>
				<button
				type="button"
				class="btn btn-blue btn-md"
				@click.prevent="addCard"
				>
				<span v-if="loading">Resolving</span>
				<span v-else>Confirm</span>
				</button>
			</div>
			</modal>
		
			<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">
				<img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

				<div class="text-lg font-bold mb-4">
					Success!
				</div>
				<div class="text-sm mb-10">
					Payout Updated Successfully.
				</div>

				<button type="button" class="btn btn-blue" @click.prevent="$refs.successModal.close">
					Okay
				</button>
			</modal>
		</div>
    </div>
  </template>
  <script>
  export default {
    props: {
      query: String,
    },
    data() {
      return {
        settlements: this.$options.resource([]),
        leadId: '',
        columns: [
          {
            name: "firstname",
            th: "Firstname",
          },
          {
            name: "lastname",
            th: "Lastname",
          },
          {
            name: "phone",
            th: "Phone Number",
          },
          {
            name: "bvn",
            th: "BVN"
          },
          {
            name: 'created_at',
            th: 'Created At',
            render: (settlement) =>
              this.$moment(settlement?.create_at).format('dddd, MMMM Do YYYY, h:mm:ss a'),
          },
          {
          name: "status",
          th: "Status",
          render: (settlement) => {
            return (settlement?.status) ?
              `<div class="badge badge-green-soft-outline px-7">
                Credit Activated
              </div>`
                :
                `<div class="badge badge-orange-soft-outline px-7">
                Pending
              </div>`
          }
        },
        ],
        actions: [
          {
            text: 'Mark User',
            class: 'border-blue-500 text-blue-500 rounded-sm px-4 py-2',
            action: this.openAddCardModal,
          },
        ],
      };
    },
    beforeMount() {
      this.paidSettlement();
    },
    methods: {
      async paidSettlement() {
        this.settlements.loading = true;
        await this.sendRequest("admin.merchants.payouts.leads", {
          success: (response) => {
            console.log('vvbvbvbn',response.data)
            this.settlements.data = response.data.data;
          },
          error: (error) => {
            console.log(error);
          },
        });
        this.settlements.loading = false;
      },
      openAddCardModal(lead) {
        this.leadId = lead.id;
        this.$refs.addCardModal.open();
      },
      closeAddCardModal() {
        this.$refs.addCardModal.close();
      },
      async addCard() {
        this.loading = true;
        await this.sendRequest(
          'admin.merchants.payouts.Onboarded',
          {
          data: {
          id: this.leadId,
          },
          success: () => {
          this.closeAddCardModal();
          this.$refs.successModal.open();
          },
          error: (error) => {
          this.requestError = error.response?.data?.message;
          },
        });
        this.loading = false;
      },
    },
  };
  </script>