<template>
    <div>
        <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
			<div class="col-span-1 flex items-center flex-wrap">
				<h2 class="text-2xl font-bold mr-3">
					{{ tab | sentenceCase }} Transactions
				</h2>
			</div>
			<div class="col-span-1">
				<div class="relative">
					<input
						type="search"
						name="search"
						class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
						placeholder="Search for merchant's name, status, etc."
						v-model="searchQuery"
					/>
					<ion-icon
						name="search-outline"
						class="text-blue-200 absolute z-10 text-lg ml-3 left-0"
						style="top: 50%; transform: translateY(-50%);"
					></ion-icon>
				</div>
			</div>
		</div>
    
        <div class="border border-solid border-blue-200">
        <div class="flex flex-col">
            <div class="duration-300">
            <datatable
                :data="settlements.data"
                :columns="columns"
                :fillable="false"
                :loading="settlements.loading"
                ref="table"
            />
            </div>
        </div>
        </div>
    </div>
  </template>
  <script>
  export default {
    props: {
      query: String,
    },
    data() {
      return {
        settlements: this.$options.resource([]),
        columns: [
        {
					name: "user_id",
					th: "User ID",
					render: (settlement) => settlement?.user_id
				},
				{
					name: "reference",
					th: "Reference",
				},
				{
					name: "merchant",
					th: "Merchant",
					render: (settlement) => settlement?.merchant?.name,
				},
				{
				name: "amount",
				th: "Amount",
				render: (settlement) => this.$options.filters.formatAmount(
					settlement?.amount,
				)
				},
				{
				name: "description",
				th: "Description",
				render: (settlement) => settlement?.description
				},
        {
            name: 'created_at',
            th: 'Created At',
            render: (settlement) =>
              this.$moment(settlement?.created_at).format('dddd, MMMM Do YYYY, h:mm:ss a'),
        },
				{
          name: "status",
          th: "Status",
          render: (settlement) => {
            return (settlement?.status === 'success') ?
              `<div class="badge badge-green-soft-outline px-7">
                ${settlement?.status}
              </div>`
                :
                `<div class="badge badge-orange-soft-outline px-7">
									${settlement?.status}
              </div>`
          }
        },
			],
      };
    },
    beforeMount() {
      this.paidSettlement();
    },
    methods: {
      async paidSettlement() {
        this.settlements.loading = true;
        await this.sendRequest("admin.merchants.payouts.getSpends", {
          success: (response) => {
            this.settlements.data = response.data.data.data;
          },
          error: (error) => {
            console.log(error);
          },
        });
        this.settlements.loading = false;
      },
    },
  };
  </script>