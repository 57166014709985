<template>
    <div>
        <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
			<div class="col-span-1 flex items-center flex-wrap">
				<h2 class="text-2xl font-bold mr-3">
					{{ tab | sentenceCase }} Merchants
				</h2>
			</div>
			<div class="col-span-1">
				<div class="relative">
					<input
						type="search"
						name="search"
						class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
						placeholder="Search for merchant's name, status, etc."
						v-model="searchQuery"
					/>
					<ion-icon
						name="search-outline"
						class="text-blue-200 absolute z-10 text-lg ml-3 left-0"
						style="top: 50%; transform: translateY(-50%);"
					></ion-icon>
				</div>
			</div>
		</div>
        <div class="border border-solid border-blue-200">
        <div class="flex flex-col">
            <div class="duration-300">
            <datatable
                :data="settlements.data"
                :columns="columns"
                :fillable="false"
                :loading="settlements.loading"
                :actions="actions"
                ref="table"
            />
            </div>
        </div>
        </div>
    </div>
  </template>
  <script>
  export default {
    props: {
      query: String,
    },
    data() {
      return {
        settlements: this.$options.resource([]),
        columns: [
          {
            name: "merchant",
            th: "Merchant",
            render: (settlement) => settlement?.merchant_name,
          },
          {
            name: "transaction_amount",
            th: "Transaction Amount",
            render: (settlement) => '₦'+ settlement?.total_transaction,
          },
          {
            name: 'created_at',
            th: 'Created At',
            render: (settlement) =>
              this.$moment(settlement?.created_at).format('dddd, MMMM Do YYYY'),
          },
        ],
        actions: [
          {
            text: 'view',
            class: 'border-blue-500 text-blue-500 rounded-sm px-4 py-2',
            action: this.viewTransactions,
          },
        ]
      };
    },
    mounted() {
      this.paidSettlement();
    },
    methods: {
      async paidSettlement() {
        this.settlements.loading = true;
        await this.sendRequest("admin.merchants.payouts.merchantList", {
          success: (response) => {
            this.settlements.data = response.data.data;
          },
          error: (error) => {
            console.log(error);
          },
        });
        this.settlements.loading = false;
      },
      viewTransactions(merchant) {
      return this.$router.push({
          name: "merchant-transactions",
          params: { merchantId: merchant.id }
        });
      },
    },
  };
  </script>