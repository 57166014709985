<template>
	<div>
		<div class="grid grid-cols-1 md:grid-cols-2 mb-4">
			<div class="col-span-1 flex items-center flex-wrap">
				<h2 class="text-2xl font-bold mr-3">
					{{ tab | sentenceCase }} Payouts
				</h2>
			</div>
			<div class="col-span-1">
				<div class="relative">
					<input
						type="search"
						name="search"
						class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
						placeholder="Search for merchant's name, status, etc."
						v-model="searchQuery"
					/>
					<ion-icon
						name="search-outline"
						class="text-blue-200 absolute z-10 text-lg ml-3 left-0"
						style="top: 50%; transform: translateY(-50%);"
					></ion-icon>
				</div>
			</div>
		</div>
		<tabs v-model="tab" :tabs="tabs" class="mb-6" />
		<div>
			<component :is="tab" :query="searchQuery" />
		</div>
	</div>
</template>
<script>
export default {
	components: {
		pending: () => import("./Pending"),
		paid: () => import("./Paid"),
	},
	data() {
		return {
			tab: "pending",
			searchQuery: "",
			tabs: [
				{ name: "pending", title: "Pending" },
				{ name: "paid", title: "Paid" },
			],
		};
	},
};
</script>